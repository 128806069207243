<template>
  <v-dialog v-model="show" persistent fullscreen>
    <v-card>
      <v-card-title>
        Configuración de envío de correo y métodos de ingreso a la plataforma
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="8">
            <dl class="flex-grow-1 flex-shrink-1" style="flex-basis: 50%">
              <dt class="font-weight-bold text-body-2 mt-3">
                Métodos de autenticación disponibles
              </dt>
              <dd class="text-body-2 mb-8">
                <v-data-table
                  :headers="headers_metodos"
                  :items="metodos"
                  no-data-text="No tiene métodos de autenticación registrados"
                  disable-pagination
                  hide-default-footer
                >
                  <template v-slot:[`item.nombre`]="{ item }">
                    <v-icon
                      :color="
                        item.MetodoAutenticacionUsuario.is_primary
                          ? 'green'
                          : 'red'
                      "
                    >
                      {{ item.icono }}
                    </v-icon>
                    {{ item.nombre }}
                  </template>
                  <template v-slot:[`item.acciones`]="{ item }">
                    <v-tooltip top v-if="!item.permite_eliminar">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-on="on"
                          v-bind="attrs"
                          @click="
                            (modal_confirmar_cambio = true),
                              (metodo = item.MetodoAutenticacionUsuario.id)
                          "
                          >mdi-check-decagram-outline</v-icon
                        >
                      </template>
                      <span> Establecer como principal</span>
                    </v-tooltip>
                    <v-tooltip top v-else>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-on="on"
                          v-bind="attrs"
                          @click="
                            (modal_confirmar_eliminacion = true),
                              (metodo = item.MetodoAutenticacionUsuario.id)
                          "
                          >mdi-alpha-x-box</v-icon
                        >
                      </template>
                      <span> Eliminar</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </dd>
            </dl>
          </v-col>
          <v-col cols="12" md="4">
            <dl class="flex-grow-1 flex-shrink-1" style="flex-basis: 50%">
              <dt class="font-weight-bold text-body-2 mt-3">
                Configuración de envío de correos
              </dt>
              <dd class="text-body-2 mb-8">
                <v-row>
                  <v-col cols="12" md="12">
                    <dl
                      class="flex-grow-1 flex-shrink-1"
                      style="flex-basis: 50%"
                    >
                      <dt class="font-weight-bold text-body-2 mt-3">
                        Métodos configurado actualmente
                      </dt>
                      <dd class="text-body-2 mb-8">
                        <v-row>
                          <v-col cols="12" md="12">
                            {{ metodo_correo?.nombre }}
                          </v-col>
                        </v-row>
                      </dd>
                    </dl>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12">
                    <dl
                      class="flex-grow-1 flex-shrink-1"
                      style="flex-basis: 50%"
                    >
                      <dt class="font-weight-bold text-body-2 mt-3">
                        Reconfigurar:
                      </dt>
                      <dd class="text-body-2 mb-8">
                        <v-row>
                          <v-col cols="12" md="12">
                            <v-autocomplete
                              :items="metodos_correo"
                              outlined
                              item-text="nombre"
                              item-value="id"
                              label="Seleccione el método"
                              @change="cambiarMetodoCorreo"
                              v-model="metodo_correo_seleccionado"
                            ></v-autocomplete>
                          </v-col>
                        </v-row>
                      </dd>
                    </dl>
                  </v-col>
                </v-row>
              </dd>
            </dl>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn block class="primary" @click="cerrarModal">Cerrar</v-btn>
      </v-card-actions>
    </v-card>
    <ConfirmationDialogComponent
      :show="modal_confirmar_cambio"
      btnConfirmar="Realizar cambio"
      :title="`¿Desea realizar el cambio de método de autentiación?`"
      message="Dependiendo del método, este funcionará de manera temporal, es decir, volverá a la normalidad luego del siguiente inicio de sesión."
      @close="modal_confirmar_cambio = false"
      @confirm="realizarCambio"
    />
    <ConfirmationDialogComponent
      :show="modal_confirmar_eliminacion"
      btnConfirmar="Realizar cambio"
      :title="`¿Desea eliminar el método de autentiación?`"
      message="Esta acción será permanente."
      @close="modal_confirmar_eliminacion = false"
      @confirm="eliminar"
    />
  </v-dialog>
</template>
<script>
import ConfirmationDialogComponent from "@/components/ConfirmationDialogComponent.vue";

export default {
  name: "ModalconfiguracionUsuario",
  components: { ConfirmationDialogComponent },
  data() {
    return {
      headers_metodos: [
        {
          text: "Metodo",
          align: "start",
          sortable: false,
          value: "nombre",
          width: "25%",
        },
        {
          text: "Descripción",
          align: "start",
          sortable: false,
          value: "descripcion",
          width: "25%",
        },
        {
          text: "Acciones",
          align: "center",
          sortable: false,
          value: "acciones",
          width: "25%",
        },
      ],
      metodos: [],
      modal_confirmar_cambio: false,
      modal_confirmar_eliminacion: false,
      metodo: null,
      metodo_correo: null,
      metodos_correo: [],
      metodo_correo_seleccionado: null,
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    id_usuario: {
      type: Number,
    },
  },
  watch: {
    id_usuario(val) {
      if (val) {
        this.obtenerMetodosUsuario();
        this.obtenerConfiguracionCorreo();
      }
    },
  },
  methods: {
    async obtenerMetodosUsuario() {
      const { data } = await this.services.Autenticacion.obtenerMetodosUsuario(
        this.id_usuario
      );
      this.metodos = data;
    },
    cerrarModal() {
      this.metodo_correo = null;
      this.metodos_correo = [];
      this.metodos = [];
      this.$emit("cerrar");
    },
    async realizarCambio() {
      await this.services.Autenticacion.actualizarMetodo(
        this.id_usuario,
        this.metodo
      );
      this.metodo = null;
      this.modal_confirmar_cambio = false;
      await this.obtenerMetodosUsuario();
    },
    async eliminar() {
      await this.services.Autenticacion.eliminarMetodo(this.metodo);
      this.metodo = null;
      this.modal_confirmar_eliminacion = false;
      await this.obtenerMetodosUsuario();
    },
    async obtenerConfiguracionCorreo() {
      const {
        data: { metodo, metodos },
      } =
        await this.services.administracionCorreoServices.obtenerConfiguracionUsuario(
          this.id_usuario
        );
      this.metodo_correo = metodo;
      this.metodos_correo = metodos;
    },
    async cambiarMetodoCorreo() {
      if (!this.metodo_correo_seleccionado) return;
      await this.services.administracionCorreoServices.configurarMetodoCorreo(
        this.id_usuario,
        this.metodo_correo_seleccionado
      );
      await this.obtenerConfiguracionCorreo();
      this.metodo_correo_seleccionado = null;
    },
  },
};
</script>
<style scoped>
@media (min-width: 960px) {
  .grupo-detalles {
    gap: 24px;
  }
}
</style>
