<template>
  <div>
    <v-dialog
      v-model="modalIngresarSancion"
      :max-width="1000"
      content-class="modal-style"
      persistent
    >
      <v-card elevation="8" outlined>
        <v-btn class="mt-2 ml-3" icon @click="cerrarModal">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <section class="pt-0">
          <v-row>
            <v-col cols="12" xl="5" lg="5" md="5" sm="5" xs="12" class="pb-0">
              <v-card-title class="mx-3 text-h5 secondary--text">
                {{ this.id_sancion ? "Actualizar" : "Crear" }} sanción
              </v-card-title>
            </v-col>
          </v-row>

          <v-card-text class="pb-0">
            <section>
              <v-row>
                <v-col cols="6">
                  <vc-date-picker
                    locale="es"
                    mode="date"
                    :model-config="modelConfig"
                    :popover="config_calendar"
                    :value="formSancion.fecha_inicio"
                    @input="updateFechaInicio"
                  >
                    <template v-slot="{ inputEvents }">
                      <v-text-field
                        :value="formSancion.fecha_inicio"
                        class="border roundend focus:outline-none focus:border-blue-300"
                        label="Fecha de inicio *"
                        outlined
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-on="inputEvents"
                      />
                    </template>
                  </vc-date-picker>
                </v-col>

                <v-col cols="6">
                  <vc-date-picker
                    locale="es"
                    mode="date"
                    :model-config="modelConfig"
                    :popover="config_calendar"
                    :value="formSancion.fecha_fin"
                    @input="updateFechaFin"
                  >
                    <template v-slot="{ inputEvents }">
                      <v-text-field
                        :value="formSancion.fecha_fin"
                        class="border roundend focus:outline-none focus:border-blue-300"
                        label="Fecha de fin *"
                        outlined
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-on="inputEvents"
                      />
                    </template>
                  </vc-date-picker>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-textarea
                    outlined
                    label="Detalle de sanción *"
                    v-model="formSancion.detalle_sancion"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="institucionSeleccionada"
                    outlined
                    label="Institución *"
                    :items="instituciones"
                    item-text="nombre"
                    item-value="id"
                    clearable
                    :disabled="!tieneRolActualizar"
                  />
                </v-col>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="tipoSancionSeleccionada"
                    outlined
                    label="Tipo de sanción *"
                    :items="tipos_sancion"
                    item-text="nombre"
                    item-value="id"
                    clearable
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-btn
                    v-if="this.formSancion?.documento"
                    outlined
                    color="secondary"
                    class="mb-4"
                    @click="descargarDocumentoSancion"
                  >
                    Descargar documento actual
                  </v-btn>
                  <v-file-input
                    accept="application/pdf"
                    clearable
                    label="Documento de sanción *"
                    outlined
                    prepend-icon=""
                    prepend-inner-icon="mdi-paperclip"
                    v-model="documento_sancion"
                    :value="documento_sancion"
                  />
                </v-col>
              </v-row>
            </section>
          </v-card-text>
          <v-card-actions>
            <section class="pt-0">
              <v-btn
                class="mx-3"
                color="secondary"
                outlined
                @click="cerrarModal"
                >Cerrar
              </v-btn>
              <v-btn
                v-if="this.id_sancion"
                class="mx-3"
                color="secondary"
                @click="actualizarSancion()"
                :disabled="!tieneRolActualizar"
              >
                Actualizar sanción
              </v-btn>
              <v-btn
                v-else
                class="mx-3"
                color="secondary"
                @click="guardarSancion()"
              >
                Crear sanción
              </v-btn>
              <v-btn
                v-show="this.id_sancion"
                class="mx-3"
                color="error"
                @click="showDeleteUserDialog = true"
                :disabled="!tieneRolActualizar"
              >
                Eliminar sanción
              </v-btn>
            </section>
          </v-card-actions>
        </section>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showDeleteUserDialog" max-width="800" persistent>
      <v-card rounded="lg">
        <v-card-title class="d-flex justify-center primary--text">
          <p class="mt-5">
            {{
              `¿Desea eliminar la inhabilitación asociada al proveedor ${this.dataSancion?.nombre}?`
            }}
          </p>
        </v-card-title>
        <v-card-text class="d-flex justify-center secondary--text">
          <p>De confirmarse la siguiente acción, debe cargar una resolución.</p>
        </v-card-text>
        <v-card-text class="d-flex justify-center">
          <v-file-input
            v-model="adjunto"
            label="Adjunto"
            placeholder="Seleccione un PDF"
            outlined
            light
            accept="application/pdf"
          />
        </v-card-text>
        <v-card-actions class="d-flex justify-center secondary--text">
          <v-btn
            color="primary"
            class="mb-5"
            outlined
            @click="cerrarModalEliminar()"
          >
            Cancelar
          </v-btn>
          <v-btn
            :disabled="!adjunto"
            color="primary"
            class="mb-5"
            @click="deleteInhabilitacionProveedor()"
          >
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style>
.modal-style {
  overflow: hidden;
  max-width: 1000px;
  margin: auto;
}
</style>

<script>
import { required, helpers, requiredIf } from "vuelidate/lib/validators";
import moment from "moment";

const txtArea = helpers.regex(
  "txtArea",
  /^[A-Za-zñÑáéíóúÁÉÍÓÚüÜ0-9 ()/.,\n:;-]*$/
);

export default {
  name: "modalSancionIngreso",
  props: {
    modalIngresarSancion: {
      type: Boolean,
      required: true,
    },
    dataSancion: {
      type: Object,
    },
    detalleSancion: {
      type: Object,
    },
  },
  data() {
    return {
      documento_sancion: null,
      id_sancion: null,
      snapshot: null,
      tieneRolActualizar: false,
      showDeleteUserDialog: false,
      adjunto: null,
      instituciones: [],
      institucionSeleccionada: null,
      tipoSancionSeleccionada: null,
      sancionStore: null,
      detalleSancionLocal: null,
      modelConfig: {
        type: "string",
        mask: "DD/MM/YYYY",
      },
      config_calendar: {
        visibility: "click",
      },
      validations: {
        formSancion: {
          fecha_inicio: {
            required,
          },
          fecha_fin: {
            required,
          },
          detalle_sancion: {
            required,
            txtArea,
          },
        },
      },
      formSancion: {
        fecha_inicio: null,
        fecha_fin: null,
        detalle_sancion: "",
      },
      tipos_sancion: [],
    };
  },
  computed: {},
  methods: {
    updateFechaInicio(value) {
      this.formSancion.fecha_inicio = value;
    },
    updateFechaFin(value) {
      this.formSancion.fecha_fin = value;
    },
    formatDate(dateString) {
      return moment(dateString).format("DD/MM/YYYY");
    },

    cerrarModal() {
      this.$emit("cerrarModal");
      this.$emit("modal-cerrado");
      this.resetForm();
    },
    async guardarSancion() {
      if (
        !this.formSancion.detalle_sancion &&
        !this.formSancion.fecha_inicio &&
        !this.formSancion.fecha_fin &&
        !this.formSancion.documento_sancion
      ) {
        this.temporalAlert({
          show: true,
          message: "Debe completar los campos para poder continuar",
          type: "error",
        });
        return;
      } else {
        if (!this.formSancion.fecha_inicio) {
          this.temporalAlert({
            show: true,
            message: "Debe completar el campo fecha de inicio",
            type: "error",
          });
          return;
        } else if (!this.formSancion.fecha_fin) {
          this.temporalAlert({
            show: true,
            message: "Debe completar el campo fecha de finalización",
            type: "error",
          });
          return;
        } else if (!this.formSancion.detalle_sancion) {
          this.temporalAlert({
            show: true,
            message: "Debe completar el campo detalle de la sanción",
            type: "error",
          });
          return;
        } else if (!this.documento_sancion) {
          this.temporalAlert({
            show: true,
            message: "Debe completar el campo documento de sancion",
            type: "error",
          });
          return;
        } else if (!this.institucionSeleccionada) {
          this.temporalAlert({
            show: true,
            message:
              "Debe completar el campo institución que genera la sanción",
            type: "error",
          });
          return;
        } else if (!this.tipoSancionSeleccionada) {
          this.temporalAlert({
            show: true,
            message:
              "Debe completar el campo del tipo de sanción que se generará",
            type: "error",
          });
        }
      }

      const formData = new FormData();
      formData.append("id_proveedor", this.dataSancion.id);
      formData.append("fecha_inicio", this.formSancion.fecha_inicio);
      formData.append("fecha_fin", this.formSancion.fecha_fin);
      formData.append("detalle_sancion", this.formSancion.detalle_sancion);
      formData.append("documento_sancion", this.documento_sancion);
      formData.append(
        "id_institucion",
        this.institucionSeleccionada.id ?? this.institucionSeleccionada
      );
      formData.append("id_tipo_sancion", this.tipoSancionSeleccionada);

      const response = await this.services.Sanciones.postSancionHomologada(
        formData
      );
      if (response.status === 201) {
        this.temporalAlert({
          show: true,
          message: "Sanción registrada correctamente",
          type: "success",
        });
        this.cerrarModal();
      }
    },
    async actualizarSancion() {
      if (
        !this.formSancion.detalle_sancion &&
        !this.formSancion.fecha_inicio &&
        !this.formSancion.fecha_fin
      ) {
        this.temporalAlert({
          show: true,
          message: "Debe completar los campos para poder continuar",
          type: "error",
        });
        return;
      } else {
        if (!this.formSancion.fecha_inicio) {
          this.temporalAlert({
            show: true,
            message: "Debe completar el campo fecha de inicio",
            type: "error",
          });
          return;
        } else if (!this.formSancion.fecha_fin) {
          this.temporalAlert({
            show: true,
            message: "Debe completar el campo fecha de finalización",
            type: "error",
          });
          return;
        } else if (!this.formSancion.detalle_sancion) {
          this.temporalAlert({
            show: true,
            message: "Debe completar el campo detalle de la sanción",
            type: "error",
          });
          return;
        } else if (!this.institucionSeleccionada) {
          this.temporalAlert({
            show: true,
            message:
              "Debe completar el campo institución que genera la sanción",
            type: "error",
          });
          return;
        } else if (!this.tipoSancionSeleccionada) {
          this.temporalAlert({
            show: true,
            message:
              "Debe completar el campo del tipo de sanción que se generará",
            type: "error",
          });
        }
      }

      const formData = new FormData();
      formData.append("fecha_inicio", this.formSancion.fecha_inicio);
      formData.append("fecha_fin", this.formSancion.fecha_fin);
      formData.append("detalle_sancion", this.formSancion.detalle_sancion);
      if (this.documento_sancion !== null) {
        formData.append("documento_sancion", this.documento_sancion);
      }
      formData.append("id_institucion", this.institucionSeleccionada);
      const response = await this.services.Sanciones.putSancionHomologadaByid(
        this.id_sancion,
        formData
      );

      formData.append("id_tipo_sancion", this.tipoSancionSeleccionada);

      if (response.status === 200) {
        this.temporalAlert({
          show: true,
          message: "Sanción actualizada correctamente",
          type: "success",
        });
        this.cerrarModal();
      }
    },
    async getSancion() {
      this.id_sancion = null;

      const id_proveedor = this.dataSancion.id;
      const { data, status } =
        await this.services.Sanciones.getSancionHomologadaById(id_proveedor);

      if (status === 200) {
        if (data) {
          (this.formSancion = {
            ...data,
            fecha_inicio: this.formatDate(data.fecha_inicio),
            fecha_fin: this.formatDate(data.fecha_fin),
          }),
            (this.id_sancion = data.id);
          this.sancionStore = data;
          this.$emit("detalle-sancion-actualizado", this.sancionStore);
        } else {
          this.formSancion = {};
        }

        this.modalIngresarSancion = true;
      }
    },

    async resetForm() {
      this.formSancion = {
        fecha_inicio: null,
        fecha_fin: null,
        detalle_sancion: "",
      };
      this.institucionSeleccionada = null;
      this.documento_sancion = null;
      this.tipoSancionSeleccionada = null;
    },

    async descargarDocumentoSancion() {
      const response =
        await this.services.PacProcesos.descargarDocumentoProceso({
          disk: "sanciones",
          ruta: this.formSancion.documento,
        });

      if (!response) {
        this.temporalAlert({
          show: true,
          message: "Ocurrió un error al descargar el documento",
          type: "error",
        });

        return;
      }

      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });

      const file = new File([blob], "documento_sancion", {
        type: response.headers["content-type"],
      });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(file);
      link.download = "";
      link.click();
    },

    verificarRol() {
      this.tieneRolActualizar = this.haveRole(
        "ROLE_RUPES_ACTUALIZAR_SANCION_PROVEEDORES_REGISTRADOS"
      );
    },

    cerrarModalEliminar() {
      this.showDeleteUserDialog = false;
      this.adjunto = null;
    },

    async deleteInhabilitacionProveedor() {
      const formData = new FormData();
      formData.append("adjunto", this.adjunto);

      const response =
        await this.services.Sanciones.deleteInhabilitacionProveedorRegistrado(
          this.id_sancion,
          formData
        );

      if (response?.status === 200) {
        this.temporalAlert({
          show: true,
          type: "success",
          message: "Registro eliminado con éxito",
        });
        this.cerrarModal();
        this.cerrarModalEliminar();
      }
    },
    async cargarInstituciones() {
      const response =
        await this.services.Sanciones.getInstitucionUsuarioCatalogo();
      if (response?.status === 200) {
        this.instituciones = response.data?.instituciones;
        this.institucionEmpleado = response.data?.idInstitucionEmpleado[0];
      }
    },

    async dataInstitucion() {
      this.institucionSeleccionada = this.instituciones.find(
        (inst) => inst.id === this.institucionEmpleado
      );

      if (this.detalleSancionLocal) {
        this.institucionSeleccionada = this.detalleSancionLocal?.id_institucion;
      }
      this.detalleSancionLocal = null;
    },
    async obtenerTipossancion() {
      const { data } = await this.services.Sanciones.obtenerTiposSanciones();
      this.tipos_sancion = data;
    },
  },
  async created() {
    this.verificarRol();
    this.cargarInstituciones();
    this.obtenerTipossancion();
  },

  watch: {
    modalIngresarSancion: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue && newValue === true) {
          this.dataInstitucion();
        }
      },
    },

    detalleSancion: {
      immediate: true,
      handler(newDetalleSancion) {
        this.detalleSancionLocal = null;

        if (newDetalleSancion != null) {
          this.detalleSancionLocal = newDetalleSancion;
        }
        this.dataInstitucion();
      },
    },
  },
};
</script>
