<template>
  <section>
    <!-- title -->
    <div class="my-6 text-center text-sm-left">
      <span
        class="text-h5 secondary--text"
        v-text="'Proveedores registrados'"
      />
    </div>

    <v-row class="mt-4">
      <v-col cols="12" sm="8" md="6">
        <v-text-field
          label="Buscar por: nombre de proveedor, nombre comercial o documento"
          v-model="filterNombre"
          hide-details="auto"
          outlined
          maxlength="50"
          clearable
          @click:clear="(filterNombre = null), filterName()"
          @keyup="filterName"
        />
      </v-col>
      <v-col>
        <v-btn
          v-if="ShowBotonReporte"
          dark
          block
          color="secondary"
          @click="(page = 1), obtenerReporte()"
          >Reporte</v-btn
        >
      </v-col>
      <v-spacer />
      <v-col align-self="center" cols="12" sm="8" md="6">
        <v-btn
          v-if="haveRoles(['ROLE_SUPER_ADMIN'])"
          color="success"
          :loading="porcentaje.isLoading"
          @click="cargarPorcentaje()"
        >
          <v-icon>mdi mdi-reload</v-icon>
          Cargar porcentaje a proveedores
        </v-btn>
      </v-col>

      <v-col cols="12">
        <div class="mt-4">
          <data-table-component
            :show_loading="dataLoading"
            :headers="columnasFiltradas"
            :items="proveedores"
            v-models:select="porPaginaModel"
            v-models:pagina="paginaModel"
            @paginar="paginar"
            :total_registros="filters.total_rows"
            mobile_breakpoint="1000"
          >
            <template #[`item.porcentaje`]="{ item }">
              <span
                v-if="
                  item.porcentaje !== 100 &&
                  item.id_estado_verificacion === null
                "
                >{{ item.porcentaje }} %</span
              >
              <label v-else-if="item.id_estado_verificacion === 3">
                <label
                  v-if="
                    haveRoles([
                      'ROLE_DIRECCION_COMPRAS',
                      'ROLE_TECNICO_DIRECCION_COMPRAS',
                    ])
                  "
                  class="warning py-2 white--text d-block rounded-pill"
                >
                  Pendiente de verificación ({{ item.porcentaje }}%)
                </label>
                <label v-else> {{ item.porcentaje }} % </label>
              </label>
              <v-tooltip v-else-if="item.id_estado_verificacion === 1" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-on="on"
                    v-bind="attrs"
                    color="yellow accent-4"
                    size="30"
                    >mdi-check-decagram</v-icon
                  >
                </template>
                <span>Perfil verificado</span>
              </v-tooltip>
              <v-tooltip v-else top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-on="on" v-bind="attrs" color="success" size="30"
                    >mdi-check-decagram</v-icon
                  >
                </template>
                <span>100 %</span>
              </v-tooltip>
            </template>

            <template #[`item.is_suspended`]="{ item }">
              <v-chip
                dark
                label
                :color="
                  item.conSanciones
                    ? 'error'
                    : item.is_suspended || item.is_disabled
                    ? 'error'
                    : 'success'
                "
              >
                {{ estadoVisible(item) }}
                {{
                  item?.sancion?.TipoSancionProveedor
                    ? `por ${item?.sancion?.TipoSancionProveedor?.nombre}`
                    : ""
                }}
              </v-chip>
            </template>

            <template #[`item.accion`]="{ item, index }">
              <app-menu-acciones
                :menu="getAcciones(item)"
                titulo="Acciones"
                :elemento="item"
              ></app-menu-acciones>
            </template>
          </data-table-component>
        </div>
      </v-col>
    </v-row>

    <v-dialog v-model="modalReporte" max-width="600">
      <v-card class="pa-4">
        <v-card-text>
          <p class="text-center text-h6 secondary--text my-4">
            El reporte iniciará su ejecución en:
          </p>
          <app-countdown
            v-if="contador"
            color="info--text"
            :date-now="contador"
            :nextDay="finalizacionTimer"
            @status="modalReporte = false"
          />
          <p class="text-center text-h6 secondary--text my-4">
            Se le notificará al correo cuando el reporte haya finalizado. Si
            después de 15 minutos no le ha llegado el correo vuelva a ejecutar
            el proceso.
          </p>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn dark color="secondary" @click="modalReporte = false"
            >Cerrar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row
      class="mt-8"
      v-if="
        !haveRoles([
          'ROLE_RUPES_PROVEEDORES_LIST',
          'ROLE_JEFE_UNIDAD',
          'ROLE_TECNICO_UNIDAD',
        ])
      "
    >
      <v-col cols="12" sm="3" md="4">
        <v-btn
          outlined
          block
          color="secondary"
          :to="{ name: 'rupes', replace: true }"
        >
          Volver a RUPES
        </v-btn>
      </v-col>
    </v-row>

    <v-dialog v-model="modalAlertView" max-width="600" persistent>
      <v-card>
        <div class="headerIcon d-flex justify-start pr-4 pt-4">
          <v-icon class="secondary--text" @click="modalAlertView = false">
            mdi-close
          </v-icon>
        </div>
        <v-card-text>
          <div>
            <p class="text-h6 secondary--text">Detalle de alerta</p>
            <div class="text-wrap text-center mt-3" v-html="alerta_selected" />
          </div>
          <!-- acciones -->
          <v-row class="d-flex justify-center mx-sm-4 mb-6 mt-6">
            <v-btn
              class="button-extra text-capitalize mt-4 mt-sm-0 mx-8 mx-sm-4"
              color="secondary"
              outlined
              @click="modalAlertView = false"
            >
              Cerrar
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <modalSancionIngreso
      ref="modalIngresarSancion"
      :modalIngresarSancion="modalIngresarSancion"
      @cerrarModal="closeModal"
      :dataSancion="dataSancion"
      @modal-cerrado="actualizarListaProveedores"
      @detalle-sancion-actualizado="handleDetalleSancionActualizado"
      :detalleSancion="detalleSancion"
    />

    <ConfirmationDialogComponent
      :show="modalEstadoUsuario"
      btnConfirmar="Aceptar"
      :title="`¿Está seguro de ${
        userSelectedToAction?.is_suspended ? 'desbloquear' : 'bloquear'
      } al usuario?`"
      message="Esta acción afectará al acceso del proveedor al sistema"
      @close="modalEstadoUsuario = false"
      @confirm="bloquearUsuario"
    />

    <ConfirmationDialogComponent
      :show="modalEstadoProveedor"
      btnConfirmar="Aceptar"
      :title="`¿Está seguro de ${
        userSelectedToAction?.is_disabled ? 'habilitar' : 'deshabilitar'
      } al usuario?`"
      message="Esta acción afectará al acceso del proveedor al sistema"
      @close="modalEstadoProveedor = false"
      @confirm="deshabilitarUsuario"
    />

    <ConfirmationDialogComponent
      :show="showModalResetPassword"
      btnConfirmar="Aceptar"
      :title="`¿Desea restablecer la contraseña del proveedor?`"
      message="El proveedor recibirá un correo con una contraseña temporal. Al primer inicio de sesión el sistema le solicitará cambiar la contraseña por defecto."
      @close="showModalResetPassword = false"
      @confirm="sendTemporalPassword"
    />
    <ConfirmationDialogComponent
      :show="showModalObservacion"
      btnConfirmar="Enviar"
      :title="`¿Desea remover la inactividad?`"
      message="El proveedor deberá ingresar al sistema esta semana, de lo contrario será marcado como inactivo nuevamente."
      @close="showModalObservacion = false"
      @confirm="removerObservacion"
    />

    <modalDetailProvComponent ref="modal_detail_prov" />
    <ModalconfiguracionUsuario
      :show="modalConfiguracion"
      :id_usuario="id_usuario"
      @cerrar="(modalConfiguracion = false), (id_usuario = null)"
    />

    <!-- footer -->
    <footer
      tile
      class="py-4 mt-8 d-flex flex-column flex-sm-row"
      color="bgMinsal"
    >
      <!-- boton que redirecciona a la vista anterior -->
      <v-btn
        class="button-extra text-capitalize mt-4 mt-sm-0"
        color="secondary"
        outlined
        @click="$router.back()"
      >
        Volver
      </v-btn>
    </footer>
  </section>
</template>

<script>
import { mapMutations } from "vuex";
import DataTableComponent from "../../components/DataTableComponent.vue";
import ConfirmationDialogComponent from "../../components/ConfirmationDialogComponent.vue";
import modalDetailProvComponent from "./components/modalDetailProvComponent.vue";
import ModalconfiguracionUsuario from "./components/ModalconfiguracionUsuario.vue";
import {
  createLoadable,
  toggleLoadable,
  setLoadableResponse,
} from "@/utils/loadable";
import moment from "moment";
import modalSancionIngreso from "./components/modalSancionIngreso.vue";

export default {
  name: "listProveedoresView",
  components: {
    DataTableComponent,
    ConfirmationDialogComponent,
    modalDetailProvComponent,
    modalSancionIngreso,
    ModalconfiguracionUsuario,
  },
  data() {
    return {
      porcentaje: createLoadable([]),
      timer: null,
      headers: [
        {
          text: "Nombre del proveedor",
          value: "nombre",
        },
        {
          text: "Nombre comercial",
          value: "nombre_comercial",
        },
        {
          text: "Clasificación empresarial",
          value: "clasificacion_empresa",
        },
        {
          text: "%",
          value: "porcentaje",
          align: "center",
        },
        {
          text: "Estado",
          value: "is_suspended",
          align: "center",
        },
        {
          text: "Acciones",
          value: "accion",
          align: "center",
        },
      ],
      proveedores: [],
      filters: {
        page: 1,
        per_page: 10,
        total_rows: null,
        pagination: true,
        busqueda: null,
      },
      filterNombre: null,
      dataLoading: false,
      alerta_selected: null,
      modalAlertView: false,
      modalEstadoUsuario: false,
      modalEstadoProveedor: false,
      userSelectedToAction: null,
      showModalResetPassword: false,
      loadingExport: false,
      modalReporte: false,
      contador: null,
      finalizacionTimer: null,
      show_detail_modal: false,
      modalIngresarSancion: false,
      dataSancion: {},
      detalleSancion: {},
      sancionProveedor: null,
      showModalObservacion: false,
      modalConfiguracion: false,
      id_usuario: null,
    };
  },
  methods: {
    ...mapMutations("proveedores", ["setProviderInfo"]),
    openModal(item) {
      this.dataSancion = { ...item };
      this.$nextTick(() => {
        const modalComponent = this.$refs.modalIngresarSancion;
        if (modalComponent && modalComponent.getSancion) {
          this.modalIngresarSancion = true;
          modalComponent.getSancion(item.id);
        }
      });
    },

    handleDetalleSancionActualizado(sancionStore) {
      this.detalleSancion = sancionStore;
    },

    closeModal() {
      this.modalIngresarSancion = false;
    },

    async getProveedores() {
      this.dataLoading = true;
      const response = await this.services.Proveedores.getProveedoresRupes(
        this.filters
      );

      const proveedoresData = Array.isArray(response.data.proveedor)
        ? response.data.proveedor
        : Object.values(response.data.proveedor);

      this.proveedores = proveedoresData.map((proveedor) => {
        const conSanciones =
          response.data.proveedoresConSanciones[proveedor.id];
        const sancion =
          response.data.sancionHomologada.find(
            (san) => san.id_proveedor === proveedor.id
          ) ?? {};
        return {
          ...proveedor,
          has_password_reset: false,
          conSanciones,
          sancion,
        };
      });

      this.filters.page = Number(response?.headers.page);
      this.filters.per_page = Number(response?.headers.per_page);
      this.filters.total_rows = Number(response?.headers.total_rows);
      setTimeout(() => (this.dataLoading = false), 200);
    },
    paginar(filtros) {
      const { cantidad_por_pagina, pagina } = filtros;
      this.filters.page = pagina;
      this.filters.per_page = cantidad_por_pagina;
      this.getProveedores();
    },
    filterName() {
      if (this.timer) clearTimeout(this.timer);
      this.filters.busqueda =
        this.filterNombre != "" ? this.filterNombre : null;
      this.timer = setTimeout(async () => this.getProveedores(), 500);
    },
    verProveedor(item) {
      const array_roles_router = [
        "ROLE_UACI",
        "ROLE_UACI_TECNICO",
        "ROLE_TECNICO_DIRECCION_COMPRAS",
        "ROLE_DIRECCION_COMPRAS",
        "ROLE_SUPER_ADMIN",
        "ROLE_PROVEEDORES_DETALLE_SHOW",
        "ROLE_UFI_TECNICO",
        "ROLE_TECNICO_UNIDAD",
        "ROLE_JEFE_UNIDAD",
        "ROLE_AUDITOR_INTERNO_AUIN",
        "ROLE_TACOP",
      ];

      //Array de usuarios para mostrar en forma de modal la informacion del proveedor
      const array_roles_modal = [];

      if (this.haveRoles(array_roles_router)) {
        this.$router.push({ path: `/proveedor/${item.id_usuario}` });
      } else if (this.haveRoles(array_roles_modal)) {
        this.$refs.modal_detail_prov.verParticipante(item.id);
      }
    },
    async bloquearUsuario() {
      const response = await this.services.Usuarios.cambiarEstadoUsuario(
        this.userSelectedToAction?.id_usuario
      ).catch(() => {});
      if (response?.status === 200) {
        this.temporalAlert({
          show: true,
          type: "success",
          message: response?.data?.message,
        });
      }
      this.modalEstadoUsuario = false;
      this.userSelectedToAction = null;
      this.getProveedores();
    },
    async deshabilitarUsuario() {
      const response = await this.services.Usuarios.cambiarEstadoProveedor(
        this.userSelectedToAction?.id_usuario
      ).catch(() => {});
      if (response?.status === 200) {
        this.temporalAlert({
          show: true,
          type: "success",
          message: response?.data?.message,
        });
      }
      this.modalEstadoProveedor = false;
      this.userSelectedToAction = null;
      this.getProveedores();
    },
    async sendTemporalPassword() {
      const response = await this.services.Proveedores.sendTemporalPassword(
        this.userSelectedToAction?.id_usuario
      );
      if (response?.status === 200) {
        this.proveedores[this.userSelectedToAction.index][
          "has_password_reset"
        ] = true;
        this.showModalResetPassword = false;
        this.userSelectedToAction = null;
        this.temporalAlert({
          show: true,
          type: "success",
          message: "La contraseña del proveedor ha sido restablecida",
        });
      }
    },
    async obtenerReporte() {
      this.showLoader();
      const { status } =
        await this.services.Proveedores.generarReporteProveedor({
          ...this.filters,
        });
      if (status == 200) {
        this.modalReporte = true;
      }
      this.hideLoader();
    },
    async cargarPorcentaje() {
      toggleLoadable(this.porcentaje);
      const { data } = await this.services.Proveedores.getPorcentajeProveedor();
      setLoadableResponse(this.porcentaje, data, { showAlertOnSuccess: true });
    },
    actualizarListaProveedores() {
      this.getProveedores();
    },
    estadoVisible(item) {
      if (item.observado) {
        return "Observado por inactividad";
      }
      return item.conSanciones
        ? "Inhabilitado"
        : item.is_suspended
        ? "Bloqueado"
        : item.is_disabled
        ? "Deshabilitado"
        : "Habilitado";
    },
    async removerObservacion() {
      await this.services.Usuarios.removerObservacionUsuario(
        this.userSelectedToAction.id_usuario
      );
      this.showModalObservacion = false;
      await this.getProveedores();
    },
    getAcciones(item) {
      return [
        {
          nombre: "Visualizar",
          icono: "mdi-eye",
          callback2: () => this.verProveedor(item),
          disabled: false, // No está deshabilitado
        },
        {
          nombre: "Inhabilitar usuario",
          icono: "mdi-cancel",
          callback2: () => this.openModal(item),
          disabled: !this.haveRoles([
            "ROLE_DIRECCION_COMPRAS",
            "ROLE_INHABILITAR_PROVEEDOR_RUPES_VIEW",
          ]),
        },
        {
          nombre: "Alerta",
          icono: "mdi-alert",
          callback2: () => {
            this.alerta_selected = item.alertas;
            this.modalAlertView = true;
          },
          disabled: !(
            this.haveRole("ROLE_RUPES_ALERTA_CREATE") && item.alertas != null
          ),
        },
        {
          nombre: item.is_suspended
            ? "Desbloquear usuario"
            : "Bloquear usuario",
          icono: item.is_suspended ? "mdi-lock" : "mdi-lock-open-outline",
          callback2: () => {
            this.modalEstadoUsuario = true;
            this.userSelectedToAction = item;
          },
          disabled: !this.haveRole("ROLE_DIRECCION_COMPRAS"),
        },
        {
          nombre: item.is_disabled
            ? "Habilitar usuario"
            : "Deshabilitar usuario",
          icono: item.is_disabled
            ? "mdi-account-lock"
            : "mdi-account-lock-open-outline",
          callback2: () => {
            this.modalEstadoProveedor = true;
            this.userSelectedToAction = item;
          },
          disabled: !this.haveRole("ROLE_DISABLED_USER_PROVEEDOR"),
        },
        {
          nombre: "Restablecer contraseña",
          icono: "mdi-lock-reset",
          callback2: (index) => {
            this.showModalResetPassword = true;
            this.userSelectedToAction = { ...item, index };
          },
          disabled: !(
            this.haveRoles([
              "ROLE_DIRECCION_COMPRAS",
              "ROLE_TECNICO_DIRECCION_COMPRAS",
            ]) &&
            !item.is_suspended &&
            !item.has_password_reset &&
            !item.password_temporal
          ),
        },
        {
          nombre: "Remover inactividad",
          icono: "mdi-check",
          callback2: (index) => {
            this.showModalObservacion = true;
            this.userSelectedToAction = { ...item, index };
          },
          disabled: !(
            this.haveRoles([
              "ROLE_DIRECCION_COMPRAS",
              "ROLE_TECNICO_DIRECCION_COMPRAS_REMOVER_OBSERVACION",
            ]) && item.observado
          ),
        },
        {
          nombre: "Verificar Configuración del usuario",
          icono: "mdi-account-card",
          callback2: (index) => {
            this.modalConfiguracion = true;
            this.id_usuario = item.id_usuario;
          },
          disabled: !this.haveRoles([
            "ROLE_ADMINISTRAR_ACCESOS_CORREO_USUARIO",
            "ROLE_SUPER_ADMIN",
          ]),
        },
      ];
    },
  },
  computed: {
    porPaginaModel: {
      get() {
        return this.filters.per_page;
      },
      set(value) {
        this.setFiltroPorPagina(value);
      },
    },
    paginaModel: {
      get() {
        return this.filters.page;
      },
      set(value) {
        this.setFiltroPage(value);
      },
    },
    ShowBotonReporte() {
      return this.haveRole("ROLE_PROVEEDOR_REPORTE_VIEW");
    },
    columnasFiltradas() {
      if (
        this.haveRoles([
          "ROLE_TECNICO_DIRECCION_COMPRAS",
          "ROLE_DIRECCION_COMPRAS",
        ])
      ) {
        return this.headers;
      } else {
        return this.headers.filter((header) => header.value !== "is_suspended");
      }
    },
  },
  watch: {
    filterNombre(v) {
      if (v === null) this.getProveedores();
    },
    modalAlertView(v) {
      if (!v) this.alerta_selected = null;
    },
    modalReporte(value) {
      if (value) {
        this.contador = moment();
        this.finalizacionTimer = moment().add(5, "seconds");
      } else {
        this.contador = null;
        this.finalizacionTimer = null;
      }
    },
  },
  async created() {
    await this.getProveedores();
  },
};
</script>
