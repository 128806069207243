var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('div',{staticClass:"my-6 text-center text-sm-left"},[_c('span',{staticClass:"text-h5 secondary--text",domProps:{"textContent":_vm._s('Proveedores registrados')}})]),_c('v-row',{staticClass:"mt-4"},[_c('v-col',{attrs:{"cols":"12","sm":"8","md":"6"}},[_c('v-text-field',{attrs:{"label":"Buscar por: nombre de proveedor, nombre comercial o documento","hide-details":"auto","outlined":"","maxlength":"50","clearable":""},on:{"click:clear":function($event){(_vm.filterNombre = null), _vm.filterName()},"keyup":_vm.filterName},model:{value:(_vm.filterNombre),callback:function ($$v) {_vm.filterNombre=$$v},expression:"filterNombre"}})],1),_c('v-col',[(_vm.ShowBotonReporte)?_c('v-btn',{attrs:{"dark":"","block":"","color":"secondary"},on:{"click":function($event){(_vm.page = 1), _vm.obtenerReporte()}}},[_vm._v("Reporte")]):_vm._e()],1),_c('v-spacer'),_c('v-col',{attrs:{"align-self":"center","cols":"12","sm":"8","md":"6"}},[(_vm.haveRoles(['ROLE_SUPER_ADMIN']))?_c('v-btn',{attrs:{"color":"success","loading":_vm.porcentaje.isLoading},on:{"click":function($event){return _vm.cargarPorcentaje()}}},[_c('v-icon',[_vm._v("mdi mdi-reload")]),_vm._v(" Cargar porcentaje a proveedores ")],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"mt-4"},[_c('data-table-component',{directives:[{name:"models",rawName:"v-models:select",value:(_vm.porPaginaModel),expression:"porPaginaModel",arg:"select"},{name:"models",rawName:"v-models:pagina",value:(_vm.paginaModel),expression:"paginaModel",arg:"pagina"}],attrs:{"show_loading":_vm.dataLoading,"headers":_vm.columnasFiltradas,"items":_vm.proveedores,"total_registros":_vm.filters.total_rows,"mobile_breakpoint":"1000"},on:{"paginar":_vm.paginar},scopedSlots:_vm._u([{key:`item.porcentaje`,fn:function({ item }){return [(
                item.porcentaje !== 100 &&
                item.id_estado_verificacion === null
              )?_c('span',[_vm._v(_vm._s(item.porcentaje)+" %")]):(item.id_estado_verificacion === 3)?_c('label',[(
                  _vm.haveRoles([
                    'ROLE_DIRECCION_COMPRAS',
                    'ROLE_TECNICO_DIRECCION_COMPRAS',
                  ])
                )?_c('label',{staticClass:"warning py-2 white--text d-block rounded-pill"},[_vm._v(" Pendiente de verificación ("+_vm._s(item.porcentaje)+"%) ")]):_c('label',[_vm._v(" "+_vm._s(item.porcentaje)+" % ")])]):(item.id_estado_verificacion === 1)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"yellow accent-4","size":"30"}},'v-icon',attrs,false),on),[_vm._v("mdi-check-decagram")])]}}],null,true)},[_c('span',[_vm._v("Perfil verificado")])]):_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"success","size":"30"}},'v-icon',attrs,false),on),[_vm._v("mdi-check-decagram")])]}}],null,true)},[_c('span',[_vm._v("100 %")])])]}},{key:`item.is_suspended`,fn:function({ item }){return [_c('v-chip',{attrs:{"dark":"","label":"","color":item.conSanciones
                  ? 'error'
                  : item.is_suspended || item.is_disabled
                  ? 'error'
                  : 'success'}},[_vm._v(" "+_vm._s(_vm.estadoVisible(item))+" "+_vm._s(item?.sancion?.TipoSancionProveedor ? `por ${item?.sancion?.TipoSancionProveedor?.nombre}` : "")+" ")])]}},{key:`item.accion`,fn:function({ item, index }){return [_c('app-menu-acciones',{attrs:{"menu":_vm.getAcciones(item),"titulo":"Acciones","elemento":item}})]}}],null,true)})],1)])],1),_c('v-dialog',{attrs:{"max-width":"600"},model:{value:(_vm.modalReporte),callback:function ($$v) {_vm.modalReporte=$$v},expression:"modalReporte"}},[_c('v-card',{staticClass:"pa-4"},[_c('v-card-text',[_c('p',{staticClass:"text-center text-h6 secondary--text my-4"},[_vm._v(" El reporte iniciará su ejecución en: ")]),(_vm.contador)?_c('app-countdown',{attrs:{"color":"info--text","date-now":_vm.contador,"nextDay":_vm.finalizacionTimer},on:{"status":function($event){_vm.modalReporte = false}}}):_vm._e(),_c('p',{staticClass:"text-center text-h6 secondary--text my-4"},[_vm._v(" Se le notificará al correo cuando el reporte haya finalizado. Si después de 15 minutos no le ha llegado el correo vuelva a ejecutar el proceso. ")])],1),_c('v-card-actions',{staticClass:"d-flex justify-center"},[_c('v-btn',{attrs:{"dark":"","color":"secondary"},on:{"click":function($event){_vm.modalReporte = false}}},[_vm._v("Cerrar")])],1)],1)],1),(
      !_vm.haveRoles([
        'ROLE_RUPES_PROVEEDORES_LIST',
        'ROLE_JEFE_UNIDAD',
        'ROLE_TECNICO_UNIDAD',
      ])
    )?_c('v-row',{staticClass:"mt-8"},[_c('v-col',{attrs:{"cols":"12","sm":"3","md":"4"}},[_c('v-btn',{attrs:{"outlined":"","block":"","color":"secondary","to":{ name: 'rupes', replace: true }}},[_vm._v(" Volver a RUPES ")])],1)],1):_vm._e(),_c('v-dialog',{attrs:{"max-width":"600","persistent":""},model:{value:(_vm.modalAlertView),callback:function ($$v) {_vm.modalAlertView=$$v},expression:"modalAlertView"}},[_c('v-card',[_c('div',{staticClass:"headerIcon d-flex justify-start pr-4 pt-4"},[_c('v-icon',{staticClass:"secondary--text",on:{"click":function($event){_vm.modalAlertView = false}}},[_vm._v(" mdi-close ")])],1),_c('v-card-text',[_c('div',[_c('p',{staticClass:"text-h6 secondary--text"},[_vm._v("Detalle de alerta")]),_c('div',{staticClass:"text-wrap text-center mt-3",domProps:{"innerHTML":_vm._s(_vm.alerta_selected)}})]),_c('v-row',{staticClass:"d-flex justify-center mx-sm-4 mb-6 mt-6"},[_c('v-btn',{staticClass:"button-extra text-capitalize mt-4 mt-sm-0 mx-8 mx-sm-4",attrs:{"color":"secondary","outlined":""},on:{"click":function($event){_vm.modalAlertView = false}}},[_vm._v(" Cerrar ")])],1)],1)],1)],1),_c('modalSancionIngreso',{ref:"modalIngresarSancion",attrs:{"modalIngresarSancion":_vm.modalIngresarSancion,"dataSancion":_vm.dataSancion,"detalleSancion":_vm.detalleSancion},on:{"cerrarModal":_vm.closeModal,"modal-cerrado":_vm.actualizarListaProveedores,"detalle-sancion-actualizado":_vm.handleDetalleSancionActualizado}}),_c('ConfirmationDialogComponent',{attrs:{"show":_vm.modalEstadoUsuario,"btnConfirmar":"Aceptar","title":`¿Está seguro de ${
      _vm.userSelectedToAction?.is_suspended ? 'desbloquear' : 'bloquear'
    } al usuario?`,"message":"Esta acción afectará al acceso del proveedor al sistema"},on:{"close":function($event){_vm.modalEstadoUsuario = false},"confirm":_vm.bloquearUsuario}}),_c('ConfirmationDialogComponent',{attrs:{"show":_vm.modalEstadoProveedor,"btnConfirmar":"Aceptar","title":`¿Está seguro de ${
      _vm.userSelectedToAction?.is_disabled ? 'habilitar' : 'deshabilitar'
    } al usuario?`,"message":"Esta acción afectará al acceso del proveedor al sistema"},on:{"close":function($event){_vm.modalEstadoProveedor = false},"confirm":_vm.deshabilitarUsuario}}),_c('ConfirmationDialogComponent',{attrs:{"show":_vm.showModalResetPassword,"btnConfirmar":"Aceptar","title":`¿Desea restablecer la contraseña del proveedor?`,"message":"El proveedor recibirá un correo con una contraseña temporal. Al primer inicio de sesión el sistema le solicitará cambiar la contraseña por defecto."},on:{"close":function($event){_vm.showModalResetPassword = false},"confirm":_vm.sendTemporalPassword}}),_c('ConfirmationDialogComponent',{attrs:{"show":_vm.showModalObservacion,"btnConfirmar":"Enviar","title":`¿Desea remover la inactividad?`,"message":"El proveedor deberá ingresar al sistema esta semana, de lo contrario será marcado como inactivo nuevamente."},on:{"close":function($event){_vm.showModalObservacion = false},"confirm":_vm.removerObservacion}}),_c('modalDetailProvComponent',{ref:"modal_detail_prov"}),_c('ModalconfiguracionUsuario',{attrs:{"show":_vm.modalConfiguracion,"id_usuario":_vm.id_usuario},on:{"cerrar":function($event){(_vm.modalConfiguracion = false), (_vm.id_usuario = null)}}}),_c('footer',{staticClass:"py-4 mt-8 d-flex flex-column flex-sm-row",attrs:{"tile":"","color":"bgMinsal"}},[_c('v-btn',{staticClass:"button-extra text-capitalize mt-4 mt-sm-0",attrs:{"color":"secondary","outlined":""},on:{"click":function($event){return _vm.$router.back()}}},[_vm._v(" Volver ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }